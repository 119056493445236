import React, { useEffect, useRef, useState } from "react";
import StrategyNavbar from "../Navbar/StrategyNavbar";

import GetStarted from "../StrategyPage/GetStarted";
import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";
import FooterDisclaimer from "../StrategyPage/FooterDiscliamer";
import ContactUsForm from "../Footer/ContactUsForm";
import ComparisionTableData from "./ComparisionTableData";

const Comparision = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <StrategyNavbar />
      <div className=" relative  flex flex-col md:flex-row justify-between pt-24 md:pt-32 pb-8 md:pb-32 px-4 md:px-36">
        <div className="w-full md:min-h-full ">
          <div className=" text-xl md:text-[38px] md:leading-[62px] font-rubik font-bold">
            Comparison 
          </div>
          {/* <div className="text-sm font-rubik text-[#000000]/70">
            Market Agnostic Option buying for high absolute returns, with
            superior risk adjusted returns.
          </div> */}

          <ComparisionTableData />
        </div>
      </div>
      <FooterDisclaimer />
      <GetStarted />
      <UpdateBox />
      <Footer setShow={setShow} />
      {show === true ? (
        <ContactUsForm setShow={setShow} show={show} />
      ) : null}{" "}
    </div>
  );
};

export default Comparision;
