import React, { useEffect, useRef, useState } from "react";

//React Icons
import { AlignJustify, ChevronDownIcon, X } from "lucide-react";

import Logo2 from "../assests/logo.svg";
import StrategyMobileNav from "./StrategyMobileNavbar";

import styles from "./Navbar.module.css";

import India from "../assests/india.jpeg";
import Usa from "../assests/usa.jpeg";
import { div , useNavigate } from "react-router-dom";

const StrategyNavbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();

  const closeMobileNav = () => {
    setNavOpen(false);
  };

  const [isStrategyTabOpen, setIsStrategyTabOpen] = useState(false); // Dropdown state
  const dropdownRef = useRef(null); // Ref for dropdown
  // Close dropdown if clicking outside
  useEffect(() => {
    window.top.scrollTo(0, 0);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsStrategyTabOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigation = (link) => {
   navigate(link);
   setIsStrategyTabOpen(false);
  }

  return (
    <>
      <nav className="w-full py-3  md:py-3 flex flex-row items-center justify-between fixed hrefp-0 shadow-sm backdrop-blur-lg  z-50 font-poppins bg-[#243444]">
        {/*------------------Logo--------------------------*/}
        <a href={"/"}>
          <div className=" ml-4 lg:ml-20 cursor-pointer flex flex-col items-center select-none">
            <img src={Logo2} alt="logo" width={150} />
          </div>
        </a>

        {/*------------------NAV a--------------------------*/}

        <div className="flex flex-row items-center justify-between lg:justify-start  mr-10 lg:mr-20">
          <a href="/">
            <div className="  ml-4 px-3 py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Home
            </div>
          </a>

          <div ref={dropdownRef} className={`hidden lg:flex relative`}>
            <button
              onClick={() => setIsStrategyTabOpen(!isStrategyTabOpen)}
              className={` items-center ml-4 px-3 py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer`}
            >
              Strategies <ChevronDownIcon className="ml-1 h-5 w-5" />
            </button>
            {isStrategyTabOpen && (
              <div
                className={`mt-1 rounded-lg bg-[#243444] absolute top-[55px] w-[280px]  `}
              >
                <div className="flex flex-row py-3">
                  <div>
                    <div className="flex flex-row items-center mx-4 my-2 px-2 py-2 text-[#ffc907]">
                      <img src={India} alt="India flag" className="w-4" />
                      <span className="pl-2">India</span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-50")} to={"/alpha-50"}
                    
                    className="">
                      <span className="mx-4 my-2 px-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha 50{" "}
                      </span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-100")} to={"/alpha-100"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha 100
                      </span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-200")} to={"/alpha-200"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha 200
                      </span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-500")} to={"/alpha-500"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha 500
                      </span>
                    </div>
                    <div onClick={() => handleNavigation("/india-options")} to={"/india-options"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Options
                      </span>
                    </div>
                  </div>
                  <div className="border-l border-[#ffffff]/10">
                    <div className="flex flex-row items-center mx-4 my-2 px-2 py-2 text-[#ffc907]">
                      <img src={Usa} alt="Usa flag" className="w-4" />
                      <span className="pl-2">USA</span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-s&p")} to={"/alpha-s&p"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha S&P
                      </span>
                    </div>
                    <div onClick={() => handleNavigation("/alpha-ndx")} to={"/alpha-ndx"}
                    
                    >
                      <span className="mx-4 my-2 px-2 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                        Alpha NDX
                      </span>
                    </div>
                    <div
                      className="flex flex-col items-start"
                      to={"/usa-options"}
                       onClick={() => handleNavigation("/usa-options")}
                    >
                      <span className="ml-4 px-2 py-2  text-neutral-300 rounded-lg">
                        Options
                      </span>
                      <p className="bg-[#FFC9071A]  ml-6 bg-opacity-10 border-[.1px] drop-shadow-[4px] rounded-[5px]  border-[#FFC907] mx-2  text-[#FFC907]  px-[6px] py-[1px] h-fit w-fit font-poppins text-[9px]">
                        Coming soon...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <a href="/#why-us">
            <div className="  ml-4 px-3 py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Why us?
            </div>
          </a>
          <a href="/#philosophy">
            <div className="  ml-4 px-3 py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Philosophy
            </div>
          </a>
          <a href="/#our-team">
            <div className="  ml-4 px-3 py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Our Team
            </div>
          </a>
          <a href="/comparison">
            <div className="  ml-4 px-[6px] py-1 text-neutral-300 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Comparison
            </div>
          </a>
          <a
            href="https://wealthspectrum.secondstreet.fund/wealthspectrum/portal/sign-in"
            className="ml-4"
          >
            <button className="shine hidden md:flex bg-[#ffc907] text-[#000000] font-medium text-base px-6 py-3 rounded-[4px] hover:scale-105 active:scale-95">
              Investor Login
            </button>
          </a>

          {/*------------------------Live Portfolio------------------------ */}

          {/*-----------------Mobile Nav hrefggle--------------- */}
          <div
            onClick={() => setNavOpen(!navOpen)}
            className="text-[#ffffff] cursor-pointer
            flex lg:hidden"
          >
            {navOpen ? <X size={25} /> : <AlignJustify size={25} />}
          </div>
        </div>
      </nav>
      {navOpen && (
        <StrategyMobileNav navOpen={navOpen} closeMobileNav={closeMobileNav} />
      )}
    </>
  );
};
export default StrategyNavbar;
