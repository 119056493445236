import axios from "axios";
import { Loader2, ChevronsUpDownIcon } from "lucide-react";
import React, { useEffect, useState } from "react";

const ComparisionTableData = () => {
  const [usFundsData, setUsFundsData] = useState([]);
  const [indiaIndicesData, setIndiaIndicesData] = useState([]);
  const [indiaMFData, setIndiaMFData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("US Funds"); // Default tab
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Sorting state
  const [sortedData, setSortedData] = useState([]); // Sorted data for the active tab

  const getComparisonTableData = () => {
    setIsLoading(true);
    axios
      .get(
        "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/get-comparison-table-data"
      )
      .then((res) => {
        console.log("res", res.data);

        // Set the data into respective state variables
        setUsFundsData(res.data.US_Fund || []);
        setIndiaIndicesData(res.data.India_Indices || []);
        setIndiaMFData(res.data.India_MF || []);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getComparisonTableData();
  }, []);

  useEffect(() => {
    // Update sorted data when activeTab or data changes
    if (activeTab === "US Funds") setSortedData([...usFundsData]);
    else if (activeTab === "India Indices") setSortedData([...indiaIndicesData]);
    else setSortedData([...indiaMFData]);
  }, [activeTab, usFundsData, indiaIndicesData, indiaMFData]);

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      const valueA = typeof a[key] === "string" && a[key].includes("%")
        ? parseFloat(a[key].replace("%", ""))
        : a[key];
      const valueB = typeof b[key] === "string" && b[key].includes("%")
        ? parseFloat(b[key].replace("%", ""))
        : b[key];
  
      if (valueA === valueB) return 0;
      if (direction === "asc") return valueA > valueB ? 1 : -1;
      return valueA < valueB ? 1 : -1;
    });
  
    setSortConfig({ key, direction });
    setSortedData(sorted);
  };
  

  const renderTable = (data, tableName) => {
    if (data.length === 0) {
      return <div className="text-center py-4">No data available for {tableName}</div>;
    }

    // Determine the last column dynamically
    const headers = Object.keys(data[0]);
    const lastColumnKey = headers[headers.length - 1]; // Get the last column key
    const filteredHeaders = headers.slice(0, -1); // Exclude the last column

    return (
      <div className="mt-[40px] w-full overflow-x-scroll md:overflow-hidden">
        <table className="table-auto border-[1px] border-[#cbcbcb] w-full mb-8">
          <thead>
            <tr className="bg-white text-black">
              {filteredHeaders.map((header, index) => (
                <th
                  key={index}
                  onClick={() => handleSort(header)}
                  className="p-[5px] border-[1px] font-poppins text-[14px] text-left border-[#cbcbcb] cursor-pointer"
                >
                  <div className="flex items-center space-x-2">
                    <span>{header}</span>
                    <ChevronsUpDownIcon
                      className={`h-4 w-4 ${
                        sortConfig.key === header ? "text-gray-900" : "text-gray-900"
                      }`}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => {
              const isHighlighted = row[lastColumnKey] === "1"; // Check the value of the last column
              return (
                <tr
                  key={rowIndex}
                  className={`text-black ${
                    isHighlighted ? "bg-[#ffc907] font-medium" : "bg-[#ffffff]"
                  }`}
                >
                  {filteredHeaders.map((header, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
                        row[header]?.includes("%") ? "text-right" : "text-left"
                      }`}
                    >
                      {row[header] || "-"}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="mt-4">
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-[350px]">
          <Loader2 className="h-10 w-10 text-[#000000] animate-spin" />
        </div>
      ) : (
        <div>
          {/* Tabs */}
          <div className="flex space-x-4 border-b-[1px] border-gray-200  mb-4">
            {["US Funds", "India Indices", "India Mutual Funds"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 ${
                  activeTab === tab ? "text-[16px] text-black font-bold border-b-[3px] border-black" : "font-medium text-gray-600"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Render the table for the active tab */}
          {renderTable(sortedData, activeTab)}
        </div>
      )}
    </div>
  );
};

export default ComparisionTableData;


























// import axios from "axios";
// import { Loader2 } from "lucide-react";
// import React, { useEffect, useState } from "react";

// const ComparisionTableData = () => {
//   const [usFundsData, setUsFundsData] = useState([]);
//   const [indiaIndicesData, setIndiaIndicesData] = useState([]);
//   const [indiaMFData, setIndiaMFData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const getComparisonTableData = () => {
//     setIsLoading(true);
//     axios
//       .get(
//         "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/get-comparison-table-data"
//       )
//       .then((res) => {
//         console.log("res", res.data);

//         // Set the data into respective state variables
//         setUsFundsData(res.data.US_Fund || []);
//         setIndiaIndicesData(res.data.India_Indices || []);
//         setIndiaMFData(res.data.India_MF || []);

//         setIsLoading(false);
//       })
//       .catch((err) => {
//         console.log(err);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     getComparisonTableData();
//   }, []);

//   const renderTable = (data, tableName) => {
//     if (data.length === 0) {
//       return <div className="text-center py-4">No data available for {tableName}</div>;
//     }

//     // Determine the last column dynamically
//     const headers = Object.keys(data[0]);
//     const lastColumnKey = headers[headers.length - 1]; // Get the last column key
//     const filteredHeaders = headers.slice(0, -1); // Exclude the last column

//     return (
//       <div className="mt-[40px] w-full overflow-x-scroll md:overflow-hidden">
//         <div className="text-black text-[16px] sm:text-[18px] md:text-[28px] font-bold mb-4">{tableName}</div>
//         <table className="table-auto border-[1px] border-[#cbcbcb] w-full mb-8">
//           <thead>
//             <tr className="bg-white text-black">
//               {filteredHeaders.map((header, index) => (
//                 <th
//                   key={index}
//                   className="p-[5px] border-[1px] font-poppins text-[14px] text-left border-[#cbcbcb]"
//                 >
//                   {header}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((row, rowIndex) => {
//               const isHighlighted = row[lastColumnKey] === "1"; // Check the value of the last column
//               return (
//                 <tr
//                   key={rowIndex}
//                   className={` text-black ${isHighlighted ? "bg-[#ffc907] font-medium" : "bg-[#ffffff]"}`}
//                 >
//                   {filteredHeaders.map((header, cellIndex) => (
//                     <td
//                       key={cellIndex}
//                       className={`px-[10px] font-poppins text-[14px] py-[10px] border-[1px] border-[#cbcbcb] ${
//                         row[header]?.includes('%') ? "text-right" : "text-left"
//                       }`}
//                     >
//                       {row[header] || "-"}
//                     </td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     );
//   };

//   return (
//     <div className="">
//       {isLoading ? (
//         <div className="flex items-center justify-center w-full h-[350px]">
//           <Loader2 className="h-10 w-10 text-[#000000] animate-spin" />
//         </div>
//       ) : (
//         <div>
//           {renderTable(usFundsData, "US Funds")}
//           {renderTable(indiaIndicesData, "India Indices")}
//           {renderTable(indiaMFData, "India Mutual Funds")}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ComparisionTableData;
